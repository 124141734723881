import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

// import { isNullOrUndefined } from "util";
import { Group } from "@progress/kendo-drawing";
import { JsonPipe } from "@angular/common";
import { CallserviceService } from "src/app/CallServices/callservice.service";
import { TokenEncryptionService } from "src/app/CallServices/token-encryption.service";
import { Utility } from "src/app/ModelFinancialMetrics/CommonService/utility";


@Injectable({
  providedIn: 'root'
})
export class FilterDataService {
  constructor(private toaster: ToastrService, private api: CallserviceService, private tokenEncryptDecrypt: TokenEncryptionService) {
    this.clsUtility = new Utility(toaster);
  }
  clsUtility: Utility;

  GetUserGroups(): any {
    try {
      let AllGroup: {
        groupid: string;
        groupname: string;
        tin: string;
        ccn: string;
        groupcode: string;
        qpathwaysid: string;
        isparent: boolean;
      } = {
        groupid: "0",
        groupname: "All",
        tin: null,
        ccn: null,
        groupcode: "All",
        qpathwaysid: "",
        isparent: true,
      };

      let allGroupPractice: any;
      let Groups: any;
      allGroupPractice = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("GroupPractice")));  //Decryption//JSON.parse(sessionStorage.getItem("GroupPractice"));
      Groups = [];
      if (!isNullOrUndefined(allGroupPractice)) {
        for (let i = 0; i < allGroupPractice.length; i++) {
          if (allGroupPractice[i].isparent == true) {
            Groups.push(allGroupPractice[i]);
          }
        }

        if (!isNullOrUndefined(Groups) && Groups.length > 0) {
          if (Groups.length > 1) {
            Groups.unshift(AllGroup);
          }
        }
      }

      Groups.sort(this.GetSortOrder("groupname"));
      return Groups;
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }
  Currentdataset: string;
  public practicelist: any = [];
  GetUserPractices(Parentid: any = null): any {
    try {
      let AllPractices: {
        ccn: string;
        groupcode: string;
        groupid: string;
        groupname: string;
        qpathwaysid: string;
        tin: string;
        isparent: boolean;
      } = {
        ccn: "",
        groupcode: "All",
        groupid: "0",
        groupname: "All",
        qpathwaysid: "",
        tin: "",
        isparent: false,
      };

      let allGroupPractice: any;
      let Practices: any;
      allGroupPractice = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("GroupPractice")));//JSON.parse(sessionStorage.getItem("GroupPractice"));
      //let MappedList = JSON.parse( sessionStorage.getItem("GroupWithMappedPractice")); 
      let MappedList = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("GroupWithMappedPractice")))  //Decryption
      let BQpractices: any;
      BQpractices = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("BQPractices")));  //Decryption //JSON.parse(sessionStorage.getItem("BQPractices"));
      // console.log('BQPractices',BQpractices)
      Practices = [];
      if (!isNullOrUndefined(allGroupPractice)) {
        for (let i = 0; i < allGroupPractice.length; i++) {
          if (allGroupPractice[i].isparent == false) {
            for (let j = 0; j < BQpractices.length; j++) {
              if (allGroupPractice[i].groupcode.toUpperCase() == BQpractices[j].clientkey.toUpperCase()) {
                if (isNullOrUndefined(Parentid)) {
                  Practices.push(allGroupPractice[i]);
                } else if (allGroupPractice[i].parentid == Parentid) {
                  Practices.push(allGroupPractice[i]);
                }
              }
            }

          } else {
            let sPractices: {
              ccn: string;
              groupcode: string;
              groupid: string;
              groupname: string;
              qpathwaysid: string;
              tin: string;
              isparent: boolean;
            } = {
              ccn: "",
              groupcode: allGroupPractice[i].groupcode,
              groupid: allGroupPractice[i].groupid,
              groupname: "All " + allGroupPractice[i].groupname,
              qpathwaysid: "",
              tin: "",
              isparent: true,
            };

            Practices.unshift(sPractices);
          }
        }
        for (let i = 0; i < MappedList.length; i++) {
          if (MappedList[i].isparent == false && Practices.length > 1) {
            Practices.unshift(AllPractices);
            break;
          }

          if (Practices.length > 1) {
            Practices.unshift(AllPractices);
            break;
          }
        }

        // if (!isNullOrUndefined(Practices) && Practices.length > 0) {
        //   if (!isNullOrUndefined(MappedList) && MappedList.length > 0) {
        //     let index = MappedList.findindex((x) => x.isparent == false);
        //     if (index >= 0) {
        //       Practices.unshift(AllPractices);
        //     }
        //   }
        //   // if (Practices.length > 1) {
        //   //   Practices.unshift(AllPractices);
        //   // }
        // }
      }

      // let allGroupPractice: any;
      // let Practices: any;
      // allGroupPractice = JSON.parse(sessionStorage.getItem("GroupWithMappedPractice"));
      // Practices = [];
      // if (!isNullOrUndefined(allGroupPractice)) {
      //   for (let i = 0; i < allGroupPractice.length; i++) {
      //     if (allGroupPractice[i].isparent == false) {
      //       if (isNullOrUndefined(Parentid)) {
      //         Practices.push(allGroupPractice[i]);
      //       } else if (allGroupPractice[i].groupid == Parentid) {
      //         Practices.push(allGroupPractice[i]);
      //       }
      //     }
      //   }
      // }

      Practices.sort(this.GetSortOrder("groupname"));


      // For data masking on staging environment we are changing the original names with the dummy names 
      // the dummy names comes from the pycharm in practicelist 
      this.Currentdataset = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment"));
      if (this.Currentdataset == "Staging") {
        this.practicelist = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("ListofPractice"))); //Decryption  JSON.parse(sessionStorage.getItem("ListofPractice"));
        console.log('print practicelist' , this.practicelist)
        for (let i = 0; i < Practices.length; i++) {
          for (let j = 0; j < this.practicelist.length; j++) {
            if (Practices[i].groupcode == this.practicelist[j].groupcode) {
              Practices[i].groupname = this.practicelist[j].groupname;
              break;
            }
          }
        }
      }


      return Practices;
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  //Group Practice Filter for MIU
  GetUserMIUPracticesold(Parentid: any = null): any {
    try {
      let allMIUGroupPractice: any;
      let MIUPractices: any;
      allMIUGroupPractice = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("MIUGroupPractice")));//JSON.parse(sessionStorage.getItem("GroupPractice"));
      let MIUPracticeList: any;
      MIUPracticeList = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("MIUPracticeList")));  //Decryption //JSON.parse(sessionStorage.getItem("BQPractices"));
      MIUPractices = [];
      if (!isNullOrUndefined(allMIUGroupPractice)) {
        for (let i = 0; i < allMIUGroupPractice.length; i++) {
          if (allMIUGroupPractice[i].isparent == false) { //to get single practices list
            if (allMIUGroupPractice[i].groupcode=='MIU') { 
              allMIUGroupPractice[i].groupname='MIU'
            }
            MIUPractices.push(allMIUGroupPractice[i])
            // MIUPracticeList.forEach(group => {
            //   if (group.groupcode === 'MIU' || group.groupcode === 'UC') {
            //     if(group.groupcode === 'MIU')
            //     {
            //       group.groupname='MIU'
            //     }
            //     MIUPractices.push(group);
            //   }
            // });
            // for (let j = 0; j < inew.length; j++) {

            //   if (allMIUGroupPractice[i].groupcode == MIUPracticeList[j].groupname) {

            //     if (isNullOrUndefined(Parentid)) {
            //       MIUPractices.push(allMIUGroupPractice[i]);
            //     } else if (allMIUGroupPractice[i].parentid == Parentid) {
            //       MIUPractices.push(allMIUGroupPractice[i]);
            //     }
            //   }
            // }

          } else {  //if parent is true for MIU_UC_GRP and to get childpractices separate
            let sPractices: { //to get Group practices list
              ccn: string;
              groupcode: string;
              groupid: string;
              groupname: string;
              qpathwaysid: string;
              tin: string;
              isparent: boolean;
            } = {
              ccn: "",
              groupcode: allMIUGroupPractice[i].groupcode,
              groupid: allMIUGroupPractice[i].groupid,
              groupname: "All " + allMIUGroupPractice[i].groupname,
              qpathwaysid: "",
              tin: "",
              isparent: true,
            };

            MIUPractices.unshift(sPractices);
          }
        }
      }
      MIUPractices.sort(this.GetSortOrder("groupname"));
      // console.log('MIUPractices',MIUPractices);
      return MIUPractices;
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }


  GetUserMIUPractices(Parentid: any = null): any {
    try {
      let allMIUGroupPractice: any;
      let MIUPractices: any;
      allMIUGroupPractice =  JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("MIUGroupPractice")));//JSON.parse(sessionStorage.getItem("GroupPractice"));
      let MIUPracticeList: any;
      MIUPracticeList =JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("MIUPracticeList")));  //Decryption //JSON.parse(sessionStorage.getItem("BQPractices"));
      MIUPractices = [];
      if (!isNullOrUndefined(allMIUGroupPractice)) {
        for (let i = 0; i < allMIUGroupPractice.length; i++) {
          if (allMIUGroupPractice[i].isparent == false) { //to get single practices list
            for (let j = 0; j < MIUPracticeList.length; j++) {
              if (allMIUGroupPractice[i].groupcode== MIUPracticeList[j].groupcode) {
                if (isNullOrUndefined(Parentid)) {
                  MIUPractices.push(allMIUGroupPractice[i]);
                } else if (allMIUGroupPractice[i].parentid == Parentid) {
                  MIUPractices.push(allMIUGroupPractice[i]);
                }
              }
            }
          } else {  //if parent is true for MIU_UC_GRP and to get childpractices separate
            let sPractices: { //to get Group practices list
              ccn: string;
              groupcode: string;
              groupid: string;
              groupname: string;
              qpathwaysid: string;
              tin: string;
              isparent: boolean;
            } = {
              ccn: "",
              groupcode: allMIUGroupPractice[i].groupcode,
              groupid: allMIUGroupPractice[i].groupid,
              //groupname: "All " + allMIUGroupPractice[i].groupname,
              groupname:  allMIUGroupPractice[i].groupname,
              qpathwaysid: "",
              tin: "",
              isparent: true,
            };
            MIUPractices.unshift(sPractices);
          }
        }
      }
      MIUPractices.sort(this.GetSortOrder("groupname"));
      console.log('MIUPractices',MIUPractices)
      return MIUPractices;
     
    }
    catch (error) {
      this.clsUtility.LogError(error);
    }
  }
  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }
  UserLastSelection = new Array();
  public getUserLastSelection() {
    let param: { userid: string; envname: string } = {
      userid: "",
      envname: "",
    };
    param.envname = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment")).toString();
    param.userid = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid")).toString();
    this.api.getFunctionName("UserGroupRetrieve");
    const UserSelectionCall = this.api.BindDataFMetrics(param).share();
    UserSelectionCall.map((res) => res.json()).subscribe((res) => {
      this.setUserLastSelection(res);
    });
  }

  public setUserLastSelection(res) {
    // debugger;
    if (!isNullOrUndefined(res)) {
      this.UserLastSelection = res;

      //sessionStorage.setItem("UserLastSelection", JSON.stringify(this.UserLastSelection));  
      sessionStorage.setItem('UserLastSelection', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.UserLastSelection)));   //Encryption

    }
  }
}
function isNullOrUndefined(value: any): boolean { return value === null || value === undefined; }

