import { Component, OnInit } from '@angular/core';
import { CookieService } from "ngx-cookie-service";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../AuthGuard/auth.service";
import { environment } from "./../../environments/environment";
import { DatatransaferService } from 'src/app/ModelFinancialMetrics/CommonService/datatransafer.service';
//import { SelectEndEvent } from '@progress/kendo-angular-charts';
import { CallserviceService } from '../CallServices/callservice.service';
import { ToastrService } from 'ngx-toastr';
import { TokenEncryptionService } from '../CallServices/token-encryption.service';
@Component({
  selector: 'app-setsid',
  templateUrl: './setsid.component.html',
  styleUrls: ['./setsid.component.css']
})
export class SetsidComponent implements OnInit {
  UID: string;
  AID: string;
  QPM: any;
  currDataset: string;
  currentENV: string;
  loginLoader: boolean;
  Practiceparam: { environmenttype: string; currentdataset: string } = {
    environmenttype: "",
    currentdataset: "",
  };
  practiceList: any;
  token: string;
  userId: any;
  configsecret: any;

  constructor(private cookieService: CookieService, private tokenEncryptDecrypt: TokenEncryptionService, private route: ActivatedRoute, private _Router: Router,
    private auths: AuthService, private datatransfer: DatatransaferService, private api: CallserviceService, private toastr: ToastrService,) {
    this.route.params.subscribe((params) => console.log('detect change', params));
  }

  ngOnInit() {
    try {
      this.route.params.forEach(urlParams => {
        this.UID = urlParams["uid"];
        this.AID = urlParams["aid"];
        if (urlParams["qpm"] != undefined) {
          this.QPM = JSON.parse(urlParams["qpm"]);
        } else {
          this.QPM = "";
        }
      });

      const seq = this.api.getsecretkey(this.AID).share();
      seq
        .map((res) => res)
        .subscribe(
          (res) => {
            this.configsecret = res;
            this.configsecret = this.configsecret._body;

            const randomChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            let encryptedKey = '';
            for (let i = 0; i < this.configsecret.length; i++) {
              encryptedKey += this.configsecret[i] + randomChars[Math.floor(Math.random() * randomChars.length)];
            }
            this.configsecret = encryptedKey

            sessionStorage.setItem('KEY', this.configsecret);
            this.tokenEncryptDecrypt.getkey(this.configsecret);
            this.myfunction();
          },
        );
    } catch (error) {
      console.log('setsid component Config key is null or empty')
    }
  }

  myfunction() {
    try {
      if (this.route.routeConfig.path.includes("clrSID")) {
        if (environment.currentEnvironment === "Local") {
          this.cookieService.set('UID', this.tokenEncryptDecrypt.encryptToken(this.UID), -1, undefined, undefined, false, "Lax");  //this.cookieService.set('UID', this.UID, -1, undefined, undefined, false, "Lax");
          this.cookieService.set('AID', this.tokenEncryptDecrypt.encryptToken(this.AID), -1, undefined, undefined, false, "Lax");  //this.cookieService.set('AID', this.AID, -1, undefined, undefined, false, "Lax");
        }
        else {
          this.cookieService.set('UID', this.tokenEncryptDecrypt.encryptToken(this.UID), -1, undefined, undefined, true, "None");//this.cookieService.set('UID', this.UID, -1, undefined, undefined, true, "None");
          this.cookieService.set('AID', this.tokenEncryptDecrypt.encryptToken(this.UID), -1, undefined, undefined, true, "None");//this.cookieService.set('AID', this.AID, -1, undefined, undefined, true, "None");
        }
      } else {
        if (environment.currentEnvironment === "Local") {
          this.cookieService.set('UID', this.tokenEncryptDecrypt.encryptToken(this.UID), 30, undefined, undefined, false, "Lax");//this.cookieService.set('UID', this.UID, 30, undefined, undefined, false, "Lax");
          this.cookieService.set('AID', this.tokenEncryptDecrypt.encryptToken(this.AID), 30, undefined, undefined, false, "Lax");// //this.cookieService.set('AID', this.AID, 30, undefined, undefined, false, "Lax");
        }
        else {
          this.cookieService.set('UID', this.tokenEncryptDecrypt.encryptToken(this.UID), 30, undefined, undefined, true, "None"); //this.cookieService.set('UID', this.UID, 30, undefined, undefined, true, "None");
          this.cookieService.set('AID', this.tokenEncryptDecrypt.encryptToken(this.AID), 30, undefined, undefined, true, "None"); //this.cookieService.set('AID', this.AID, 30, undefined, undefined, true, "None");
        }

        if (
          this.cookieService.check("AID") && this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")) != undefined) //this.cookieService.get("AID")
        {
          if (this.QPM == "" || this.QPM === undefined) {
            this._Router.navigate([""]);
          }
          else {
            // this.loginLoader=true;
            sessionStorage.setItem('UID', this.tokenEncryptDecrypt.encryptToken(this.cookieService.get("UID")));  //sessionStorage.setItem("UID", this.cookieService.get("UID"));
            // this.datatransfer.bIsCQMEMRDBName.next(true);
            sessionStorage.setItem("issidebar", "true");
            this.getUserId();
          }


        } else {
          console.log("cookies not set");
          window.location.assign(
            environment.Login_url
          );
        }
      }
    } catch (error) {
      console.log('error to setsid component in myfunction')
    }
  }
  getUserId() {
    this.token = "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID"));//this.cookieService.get("AID");
    sessionStorage.setItem("token", this.token);
    this.setEnv();
    // valid user login
    const seq = this.api.MQLogin(this.token).share();
    seq
      .map((res) => res.json())
      .subscribe(
        (res) => {
          if (res.status == "Wrong id") {
            console.log("invalid_user");
          } else {
            this.userId = res["userid"];
            sessionStorage.setItem('userid', this.tokenEncryptDecrypt.encryptToken(this.userId));  //Encryption sessionStorage.setItem("userid", this.userId);
            this.setEnv();
            this.getPracticeList();
          }

        },
        (err) => {
          this.toastr.warning("Invalid User");
          console.log('invalid_user');
          this.loginLoader = false;
          setTimeout(() => {
            window.location.assign(environment.Login_url);
          }, 2000);
        }
      );
  }

  setEnv() {
    if (environment.currentEnvironment == "Production") {
      this.currDataset = "QinsightProductionData";
    } else if (environment.currentEnvironment == "Development") {
      this.currDataset = "QinsightDEVData";
    } else if (environment.currentEnvironment == "QA") {
      this.currDataset = "QinsightQAData";
    } else if (environment.currentEnvironment == "Staging") {
      this.currDataset = "QinsightStagingData";
      // QinsightStagingData
    } else if (environment.currentEnvironment == "Local") {
      this.currDataset = "QinsightDEVData";
    }

    sessionStorage.setItem('currentDataset', this.tokenEncryptDecrypt.encryptToken(this.currDataset)); // Encryption sessionStorage.setItem("currentDataset", this.currDataset);

    if (environment.currentEnvironment == "Local") {
      // sessionStorage.setItem("currentEnvironment", "Development");
      sessionStorage.setItem('currentEnvironment', this.tokenEncryptDecrypt.encryptToken("Development")); // EncryptionsessionStorage.setItem("currentEnvironment", "Development");
      this.currentENV = "Development";
    } else {
      this.currentENV = environment.currentEnvironment;
      //for staging envoirment
      // if (this.currentENV == "Staging") {
      //   this.currentENV = "Production";
      //   sessionStorage.setItem(
      //     "currentEnvironment",
      //     this.currentENV
      //   );
      // }
      // else {
      // sessionStorage.setItem(
      //   "currentEnvironment",
      //   environment.currentEnvironment
      // );
      sessionStorage.setItem('currentEnvironment', this.tokenEncryptDecrypt.encryptToken(environment.currentEnvironment)); // EncryptionsessionStorage.setItem("currentEnvironment", "Development");
      // }
    }
  }

  getPracticeList() {
    this.Practiceparam.environmenttype = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment")).toString();//sessionStorage.getItem("currentEnvironment").toString();
    this.Practiceparam.currentdataset = this.currDataset;

    this.api.getFunctionName("practiceList");
    const practiceftr = this.api.BindDataRCM(this.Practiceparam).share();
    practiceftr
      .map((practice) => practice.json())
      .subscribe((practice) => {
        this.practiceList = practice;
        if (this.practiceList.length > 0) {

          var isPrac = this.practiceList.filter(x => x.clientkey.toUpperCase() === this.QPM.dbname.toUpperCase());
          if (isPrac.length > 0) {
            // this.datatransfer.QpmObject.next([{
            //   "groupname": isPrac[0].clinicname,
            //   "groupcode": isPrac[0].clientkey,
            // }]);
            let QPM = [{
              "groupname": isPrac[0].clinicname,
              "groupcode": isPrac[0].clientkey,
            }];
            sessionStorage.setItem("QPM", JSON.stringify(QPM));
            //this.userPermission();
            this.routerNavigate()
          } else {
            console.log("practice_access");
          }

        } else {
          console.log("practice_access");
        }

      });
  }

  routerNavigate() {
    if (this.QPM.reportname == "Financial Productivity") {
      console.log("dashboard_loaded_successfully");
      this._Router.navigate(["/financialmetrics/Financialproductivity"]);
    }
    if (this.QPM.reportname == "Account Receivable") {
      console.log("dashboard_loaded_successfully");
      this._Router.navigate(["/RCM/Acountrecivable"]);
    }
    if (this.QPM.reportname == "Missed Opportunities") {
      console.log("dashboard_loaded_successfully");
      this._Router.navigate(["/RCM/MissedOpportunities"]);
    }
    if (this.QPM.reportname == "CPT Analysis") {
      console.log("dashboard_loaded_successfully");
      this._Router.navigate(["/RCM/CPTAnalysis"]);
    }
    if (this.QPM.reportname == "Monthly Charges") {
      console.log("dashboard_loaded_successfully");
      this._Router.navigate(["/RCM/MonthlyCharges"]);
    }
    if (this.QPM.reportname == "Monthly Close") {
      console.log("dashboard_loaded_successfully");
      this._Router.navigate(["/RCM/MonthlyClose"]);
    }
    if (this.QPM.reportname == "MTD YTD Report") {
      console.log("dashboard_loaded_successfully");
      this._Router.navigate(["/RCM/MtdYtdReport"]);
    }

  }

  // userPermission() {
  //   // check permission
  //   const metricsdata = this.api.javaget().share();
  //   metricsdata
  //     .map((res) => res.json())
  //     .subscribe((res) => {
  //       const Permissionarray = new Array();

  //       for (let i = 0; i < res.length; i++) {
  //         Permissionarray[i] = res[i]["permissiondescription"];
  //       }

  //       var isPermission =  Permissionarray.filter(x => x === this.QPM.reportname);

  //       if (isPermission.length>0) {

  //             if (this.QPM.reportname == "Financial Productivity") {
  //               console.log("dashboard_loaded_successfully");
  //               this._Router.navigate(["/qdashboards/FinancialProductivity"]);
  //             }
  //             if (this.QPM.reportname == "Account Receivable") {
  //               console.log("dashboard_loaded_successfully");
  //               this._Router.navigate(["/qdashboards/AR"]);
  //             }
  //         }
  //        else {
  //         // users don't have permission
  //         console.log("user_dont_have_permission")
  //       }



  //     });
  //   // end permission
  // }



}
