import { Component, OnInit, Input } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { Permission, ServiceConfigPermission } from "../../../CallServices/Permission";
import { CallserviceService } from "../../../CallServices/callservice.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatatransaferService } from "src/app/ModelFinancialMetrics/CommonService/datatransafer.service";
import { TokenEncryptionService } from "src/app/CallServices/token-encryption.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  toggle: boolean = false;
  showMenu = "";
  showSubMenu = "";
  RCMMainMenu: any;
  BanqMainMenu: any;
  Biotechmainmenu: any;
  QAdminMainmenu: any;
  AdhocMainMenu: any;
  MetricsmainMenu: any;
  MetricsHomeMenu: any;
  ValueOrthoLowerSummary: any;
  // value
  LowerExtremity: any;
  UpperExtremity: any;
  OrthomainMenu: any;
  valueMainMenu: any;
  MIUMainMenu: any;
  utilcompare: any;
  reconperformance: any;
  actualrecon: any;
  BCBSactual: any;
  BCBSutil: any;
  showProviderCompensation: any;
  clinicalhide: boolean;

  ClinicalMain: any;
  mipsSeconMenu: any;
  QBOTMainMenu: any;
  ProjectsMenu: boolean;
  homeButton: any = false;
  roleslist: any;
  practicelist: any;
  mainMonitor: boolean;
  userType: string;
  MLMainmenu: any;
  CurrentEnvironment: string;
  constructor(
    private commonService: CommonService,
    public permission: Permission,
    public serviceConfigPermission: ServiceConfigPermission,
    private router: Router,
    private api: CallserviceService,
    private datatransfer: DatatransaferService,
    private tokenEncryptDecrypt: TokenEncryptionService,
  ) { }
  showsidebar: any;
  IsHrsa: any;
  bisClaimAtRisk: any;
  @Input() gridData: any[] = [];
  // isDropdownOpen: boolean = true;
  ngOnInit() {
    this.CurrentEnvironment = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment"));
    this.IsHrsa = true;
    this.clinicalhide = true;
    this.commonService.sidebarSub.subscribe((toggle) => {
      this.toggle = toggle;
    });

    let bIsCQMEMRDBName = true;

    this.datatransfer.bIsCQMEMRDBName.subscribe(data => {
      bIsCQMEMRDBName = data

    })

    if (!bIsCQMEMRDBName) {
      this.validuserfunction();
    }

    // if (this.roleslist == 0) {
    //   this.homeButton = true;
    // } else {
    //   for (let i = 0; i < this.roleslist.length; i++) {
    //     if (this.roleslist[i].rolecode == 36) {
    //       if (this.practicelist.length > 1) {
    //         this.homeButton = false;
    //       } else {
    //         this.homeButton = true;
    //       }
    //       return;
    //     } else {
    //       this.homeButton = true;
    //     }
    //   }
    // }

    // this.getcustomizedDashboards()
    // var subjectResponse = this.api.SelectDashboardRefresh.subscribe((res) => {
    //   this.getcustomizedDashboards();
    //   subjectResponse.unsubscribe();
    // });
  }

  // (click)="valueProject()"
  // valueProject() {
  //   debugger
  //   const otherProjectUrl = environment.value; // Replace with the URL of the other project
  //   window.location.href = otherProjectUrl;
  // }

  ListOfDashboards: any;
  getcustomizedDashboards() {

    try {

      /* call QinsightDEVData.SP_Customizedashboard('QinsightDEVData','','userwisedashboardlist','c41f2f7e-c7ef-11e9-932f-434753727403') */

      let CurrentDataSet = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"))  //Decryption sessionStorage.getItem("currentDataset");

      let querystr = "call " + CurrentDataSet + ".SP_Customizedashboard('" + CurrentDataSet + "','','userwisedashboardlist','" + this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid")).toString() + "','')";
      let param: { querystring: string } = { querystring: querystr };
      this.api.getFunctionName("DynamicCallsResult");

      const providerftr = this.api.BindDataRCM(param).share();
      providerftr
        .map((res) => res.json())
        .subscribe((res) => {

          this.ListOfDashboards = res;
        });
    } catch (error) {

    }
  }
  customizedDashClick(dashdetails: any) {

    //console.log(JSON.stringify(dashdetails));
    sessionStorage.setItem("DashboardDetails", JSON.stringify(dashdetails));
    /*  this.router.navigateByUrl(this.router.url).then(() => {
      this.router.onSameUrlNavigation = 'reload';
  }); */
    this.router.navigateByUrl('/qdashboards', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/qdashboards/customizedDashboards"]))
    /*  this.router.navigate(["/qdashboards/customizedDashboards"]); */
    // this.router.navigate(['/products'], { queryParams: { order: 'popular' } });
  }
  validuserfunction() {
    const metricsdata = this.api.javaget().share();
    metricsdata
      .map((res) => res.json())
      .subscribe((res) => {
        const Permissionarray = new Array();
        let y = 0;
        for (let i = 0; i < res.length; i++) {
          Permissionarray[i] = res[i]["permissiondescription"];
        }

        if (Permissionarray.length != 0) {

          for (let i = 0; i < Permissionarray.length; i++) {
            //ProviderConfiguration
            if (Permissionarray[i] === "Customized Dashboard") {
              this.permission.CustomizedDashboard = true;
            }
            if (Permissionarray[i] === "Provider Configuration") {
              this.permission.ProviderConfiguration = true;
            }
            if (Permissionarray[i] === "Provider Config by CPT, Facility") {
              this.permission.ProviderConfigurationbyPOS = true;
            }
            if (Permissionarray[i] === "Practice Configuration") {
              this.permission.QAdminPracticeConfiguration = true;
            }
            if (Permissionarray[i] === "Model Configuration") {
              this.permission.ModelConfiguration = true;
            }
            if (Permissionarray[i] === "Billing Configuration") {
              this.permission.QAdminBillingConfiguration = true;
            }
            if (Permissionarray[i] === "Practice Expected Value") {
              this.permission.ExpectedValueConfig = true;
            }
            if (Permissionarray[i] === "Day wise Payer Payment") {
              this.permission.QAdminDayWisePayerPayment = true;
            }
            if (Permissionarray[i] === "Charge vs Payment") {
              this.permission.ChargesVsPayment = true;
            }
            if (Permissionarray[i] == "Financial Cash Flow") {
              this.permission.RCMWaterfall = true;
            }
            if (Permissionarray[i] == "Revenue Cycle") {
              this.permission.RevenueCycle = true;
            }
            if (Permissionarray[i] == "Expected Collection") {
              this.permission.ExpectedCollection = true;
            }
            if (Permissionarray[i] == "CPT-Expected Collection") {
              this.permission.CPTExpectedCollection = true;
            }
            if (Permissionarray[i] == "Denial Analysis") {
              this.permission.DenialAnalysis = true;
            }
            if (Permissionarray[i] == "Denial History") {
              this.permission.DenialsHistory = true;
            }
            if (Permissionarray[i] == "Denial Management") {
              this.permission.DenialManagement = true;
            }

            //Denial configuration
            if (Permissionarray[i] == "Denial Configuration") {
              this.permission.DenialConfig = true;
            }
            if (Permissionarray[i] == "Denial Configuration") {
              this.permission.AddDenialConfig = true;
            }
            //
            if (Permissionarray[i] == "User Configuration") {
              this.permission.RCMUserConfig = true;
            }
            if (Permissionarray[i] == "TFL/DFL Configuration") {
              this.permission.TFLUserConfig = true;
            }
            if (Permissionarray[i] == "Automail Configuration") {
              this.permission.automailconfig = true;
            }
            if (Permissionarray[i] == "Missing Charges") {
              this.permission.MissingCharges = true;
            }
            if (Permissionarray[i] == "Monthly Charges") {
              this.permission.MonthlyCharges = true;
            }
            if (Permissionarray[i] == "Missed Opportunities") {
              this.permission.MissedOpportunities = true;
            }
            if (Permissionarray[i] == "Monthly Close") {
              this.permission.MonthlyClose = true;
            }
            if (Permissionarray[i] == "MTD YTD Report") {
              this.permission.MtdYtdReport = true;
            }
            if (Permissionarray[i] == "Daily Charges") {
              this.permission.DailyCharges = true;
            }
            if (Permissionarray[i] == "Daily Close") {
              this.permission.DailyClose = true;
            }
            if (Permissionarray[i] == "Monthly Payment") {
              this.permission.MonthlyPayment = true;
            }
            if (Permissionarray[i] == "Biotech Productivity") {
              this.permission.Biotechproductivity = true;
            }
            if (Permissionarray[i] == "RCM Productivity") {
              this.permission.allrcmProductivity = true;
            }
            if (Permissionarray[i] == "Ins Bal AR") {
              this.permission.dellclaimexport = true;
            }
            if (Permissionarray[i] == "Rcm Analytics") {
              this.permission.rcmanalytics = true;
            }
            if (Permissionarray[i] == "Charge Liquidation") {
              this.permission.chargeliquidation = true;
            }
            if (Permissionarray[i] == "Available Reserves") {
              this.permission.availablereserve = true;
            }
            if (Permissionarray[i] == "Appointment Census Report") {
              this.permission.appointcensusreport = true;
            }
            if (Permissionarray[i] == "RCM Productivity Beta") {
              this.permission.rcmProductivity = true;
            }
            if (Permissionarray[i] == "Panel Payer Distribution") {
              this.permission.PanelPayerDistribution = true;
            }
            if (Permissionarray[i] == "Panel Distribution") {
              this.permission.paneldistribution = true;
            }
            if (Permissionarray[i] == "Location Wise Financial Payment") {
              this.permission.LocationFinancialPayment = true;
            }
            if (Permissionarray[i] == "Practice Compensation") {
              this.permission.PracticeCompensation = true;
            }
            if (Permissionarray[i] == "Project Insight") {
              this.permission.ProjectInsight = true;
            }
            if (Permissionarray[i] == "My Projects") {
              this.permission.MyProjects = true;
            }
            if (Permissionarray[i] == "Provider Compensation") {
              this.permission.ProviderCompensation = true;
            }
            if (Permissionarray[i] == "Provider Comp by CPT, Facility") {
              this.permission.ProviderCompensationbyPOS = true;
            }
            if (Permissionarray[i] == "Daily Charge Details") {
              this.permission.DailyChargeDetails = true;
            }
            if (Permissionarray[i] == "Timely Filing") {
              this.permission.TimelyFiling = true;
            }
            if (Permissionarray[i] == "CPT Analysis") {
              this.permission.CPTAnalysis = true;
            }
            if (Permissionarray[i] == "Charge Lag And Batch Lag") {
              this.permission.chargebatchLag = true;
            }
            if (Permissionarray[i] == "Key Performance") {
              this.permission.KeyPerformance = true;
            }
            if (Permissionarray[i] == "Metrics") {
              this.permission.Metric = true;
            }
            if (Permissionarray[i] == "Aged Payments") {
              this.permission.AgedPayments = true;
            }

            if (Permissionarray[i] == "Charge Lag And Exam Finished Lag") {
              this.permission.ExamFinishLag = true;
            }
            if (Permissionarray[i] == "Claim Not Worked Upon") {
              this.permission.ClaimNotWorkedUpon = true;
            }
            if (Permissionarray[i] == "Patient Payment") {
              this.permission.PatientPayment = true;
            }
            if (Permissionarray[i] == "RCM Day Wise Payer Payment") {
              this.permission.RCMDayWisePayerPayment = true;
            }
            if (Permissionarray[i] == "Post Utilization") {
              this.permission.PostUtilization = true;
            }
            if (Permissionarray[i] == "Provider Productivity") {
              this.permission.ProviderProductivity = true;
            }
            if (Permissionarray[i] == "Reconciliation Utilzation") {
              this.permission.ReconcilationUtilization = true;
            }
            if (Permissionarray[i] == "CPT Allowed vs ASP Pricing") {
              this.permission.CPTAllowedvsASPPricing = true;
            }
            if (Permissionarray[i] == "MRI Details") {
              this.permission.MRIDetails = true;
            }
            if (Permissionarray[i] == "ERA Client Distribution") {
              this.permission.ERAClientDistribution = true;
            }
            if (Permissionarray[i] == "ERA Daywise Distribution") {
              this.permission.ERADaywiseDistribution = true;
            }
            if (Permissionarray[i] == "ERA Division Distribution") {
              this.permission.ERADivisionDistribution = true;
            }
            if (Permissionarray[i] == "Check Distribution") {
              this.permission.checkdistribution = true;
            }
            if (Permissionarray[i] == "File Distribution") {
              this.permission.filedistribution = true;
            }
            if (Permissionarray[i] == "Honest Medicare Reconciliation") {
              this.permission.honestreport = true;
            }
            if (Permissionarray[i] == "MHP QSuite-HUB Payment Reconciliation") {
              this.permission.mhphubreport = true;
            }
            if (Permissionarray[i] == "New File Distribution") {
              this.permission.newfiledistribution = true;
            }
            /*       if(Permissionarray[i] =="FileDistribution By Payer"){
              this.permission.FileDistributionByPayer = true;
            } */
            if (Permissionarray[i] == "EDI HUB Summary") {
              this.permission.practicesummary = true;
            }
            if (Permissionarray[i] == "HUB Day Wise Payer Payment") {
              this.permission.DayWisePayerPayment = true;
            }
            if (Permissionarray[i] == "Ortho lower summary") {
              this.permission.orthoLowerSummary = true;
            }
            if (Permissionarray[i] == "Account Receivable") {
              this.permission.Acountrecivable = true;
            }
            if (Permissionarray[i] == "Account Receivable Dash") {
              this.permission.AcountrecivableinDash = true;
            }
            if (Permissionarray[i] == "On Hold Claims") {
              this.permission.QPMholddashboard = true;
            }
            if (Permissionarray[i] == "Batch Distribution") {
              this.permission.BatchDistribution = true;
            }
            if (Permissionarray[i] == "Biotech Configuration") {
              this.permission.BiotechConfiguration = true;
            }
            if (Permissionarray[i] == "Insurance follow-up") {
              this.permission.Insurancefollowup = true;
            }
            if (Permissionarray[i] == "Incomplete Order") {
              this.permission.IncompleteOrder = true;
            }
            if (Permissionarray[i] == "Key Metrics") {
              this.permission.keyMetrics = true;
            }
            if (Permissionarray[i] == "Home") {
              this.permission.home = true;
            }
            if (Permissionarray[i] == "Charges Analysis") {
              this.permission.ChargesAnalysis = true;
            }
            if (Permissionarray[i] == "Payment Analysis") {
              this.permission.paymentAnalysis = true;
            }
            if (Permissionarray[i] == "Refund Analysis") {
              this.permission.RefundAnalysis = true;
            }
            if (Permissionarray[i] == "Takeback Analysis") {
              this.permission.TakebackAnalysis = true;
            }

            if (Permissionarray[i] == "Daily Tracker") {
              this.permission.DailyTracker = true;
            }
            if (Permissionarray[i] == "Payment Break UP") {
              this.permission.PaymentBreakUP = true;
            }
            if (Permissionarray[i] == "Payment Waterfall") {
              this.permission.Paymentwaterfall = true;
            }
            if (Permissionarray[i] == "Overall AR") {
              this.permission.OverallARAndbreakup = true;
            }
            if (Permissionarray[i] == "AR Aging by Payer Type") {
              this.permission.InsuranceDebitARAging = true;
            }
            if (Permissionarray[i] == "Chemo Non Chemo by Provider") {
              this.permission.ChemononChemo = true;
            }
            if (Permissionarray[i] == "Provider YTD") {
              this.permission.ProviderYTD = true;
            }
            if (Permissionarray[i] == "Rendering Provider YTD") {
              this.permission.RenderingProviderYTD = true;
            }
            if (Permissionarray[i] == "Provider Charges") {
              this.permission.ProviderCharges = true;
            }
            if (Permissionarray[i] == "Provider Payments") {
              this.permission.ProviderPayments = true;
            }
            if (Permissionarray[i] == "Insurance AR Comparision") {
              this.permission.InsuranceARComparision = true;
            }

            if (Permissionarray[i] == "Financial Productivity") {
              this.permission.Financialproductivity = true;
            }

            // if (Permissionarray[i] == "Account Receivable in Financial") {
            //   this.permission.FinancialAR = true;
            // }

            if (Permissionarray[i] == "Batch - TRIZETTO") {
              this.permission.Trizetto = true;
            }
            // if (Permissionarray[i] == "Biotech Day Wise Payer Payment") {
            //   this.permission.BiotechDayWisePayerPayment = true;
            // }
            if (Permissionarray[i] == "Rejection Rate") {
              this.permission.RejectionRate = true;
            }
            //---------------------------- value dashboards
            if (Permissionarray[i] == "Ortho lower summary") {
              this.permission.Ortholowersummary = true;
            }
            if (Permissionarray[i] == "Ortho lower IP-Risk-Stratified") {
              this.permission.OrtholowerIPRiskStratified = true;
            }
            if (Permissionarray[i] == "Ortho lower Fractures") {
              this.permission.OrtholowerFractures = true;
            }
            if (Permissionarray[i] == "Ortho lower Readmissions") {
              this.permission.OrtholowerReadmissions = true;
            }
            if (Permissionarray[i] == "Ortho upper summary") {
              this.permission.Orthouppersummary = true;
            }
            if (Permissionarray[i] == "Ortho upper IP-Risk-Stratified") {
              this.permission.OrthoupperIPRiskStratified = true;
            }
            /*    if (Permissionarray[i] == "Ortho upper Readmissions") {
                 this.permission.OrthoupperReadmissions = true;
               } */
            if (Permissionarray[i] == "Utilization Comparison") {
              this.permission.UtilizationComparison = true;
            }
            if (Permissionarray[i] == "Reconciliation Performance") {
              this.permission.ReconciliationPerformance = true;
            }
            if (Permissionarray[i] == "Actual Reconciliation") {
              this.permission.ActualReconciliation = true;
            }
            if (Permissionarray[i] == "BCBS Commercial Actual") {
              this.permission.BCBSCommercialActual = true;
            }
            if (Permissionarray[i] == "BCBS Utilizations") {
              this.permission.BCBSUtilizations = true;
            }
            if (Permissionarray[i] == "Covid-19") {
              this.permission.Covid = true;
            }
            if (Permissionarray[i] == "Quality Dashboard 2021") {
              this.permission.QualityDashboard2021 = true;
            }
            if (Permissionarray[i] == "Quality Dashboard 2021 Patient Summary") {
              this.permission.QualityDashboard2021PatientSummary = true;
            }
            if (Permissionarray[i] == "Patient Status Tracking") {
              this.permission.patienttracking = true;
            }
            if (Permissionarray[i] == "QBots Dashboard") {
              this.permission.qbotdashboard = true;
            }
            if (Permissionarray[i] == "Dashboard Loading Time") {
              this.permission.dashboardloadingtime = true;
            }
            if (Permissionarray[i] == "Practice Updated Time") {
              this.permission.dashboardupdatedtime = true;
            }
            //configuration dashboards
            if (Permissionarray[i] == "Practice Configuration : Add new practice") {
              this.permission.serviceconfigAdd = true;
            }
            if (Permissionarray[i] == "Practice Configuration : Delete practice") {
              this.permission.serviceconfigDelete = true;
            }
            if (Permissionarray[i] == "Practice Configuration : Update practice details") {
              this.permission.PracticeUpdate = true;
            }
            if (Permissionarray[i] == "Practice Configuration : Update Service details") {
              this.permission.serviceconfigUpdate = true;
            }


            if (Permissionarray[i] == "Data Services") {
              this.permission.Services = true;
            }
            if (Permissionarray[i] == "Product Usage Analytics") {
              this.permission.productusage = true;
            }
            if (Permissionarray[i] == "Alliance Summary Analytics") {
              this.permission.alliancesummaryanalytics = true;
            }
            if (Permissionarray[i] == "Practice Summary Analytics") {
              this.permission.practicesummaryanalytics = true;
            }
            if (Permissionarray[i] == "Facility Analytics") {
              this.permission.facilityanalytics = true;
            }
            if (Permissionarray[i] == "HHA Analytics") {
              this.permission.hhaanalytics = true;
            }
            if (Permissionarray[i] == "SNF Analytics") {
              this.permission.snfanalytics = true;
            }
            if (Permissionarray[i] == "PT Analytics") {
              this.permission.ptanalytics = true;
            }
            if (Permissionarray[i] == "Order Analytics") {
              this.permission.orderanalytics = true;
            }
            if (Permissionarray[i] == "MIU Key Metrics") {
              this.permission.keyMetricsMIU = true;
            }
            if (Permissionarray[i] == "MIU Charges Analysis") {
              this.permission.chargesAnalysisMIU = true;
            }
            if (Permissionarray[i] == "MIU Payment Analysis") {
              this.permission.paymentAnalysisMIU = true;
            }
            if (Permissionarray[i] == "MIU Productivity") {
              this.permission.MIUproductivity = true;
            }
            if (Permissionarray[i] == "MIU Daily Tracker") {
              this.permission.MIUdailyTracker = true;
            }
            if (Permissionarray[i] == "Expected Configuration MIU") {
              this.permission.ExpectedConfigMIU = true;
            }
            if (Permissionarray[i] == "MIU Account Receivable") {
              this.permission.ARMIUanalysis = true;
            }
            if (Permissionarray[i] == "MIU Financial Productivity") {
              this.permission.miufinancialprod = true;
            }
            if (Permissionarray[i] == "MIU Denial Management") {
              this.permission.MiuDenialManagement = true;
            }
            if (Permissionarray[i] == "ERA Ready To Post") {
              this.permission.erapaymentposting = true;
            }
            if (Permissionarray[i] == "Insurance Pending Checks") {
              this.permission.Insurancependingchecks = true;
            }
            //only for practice configuration (to set in session storage)
            if (Permissionarray[i] == "Practice Configuration : Add new practice") {
              this.serviceConfigPermission.serviceconfigAdd = true;
            }
            if (Permissionarray[i] == "Practice Configuration : Delete practice") {
              this.serviceConfigPermission.serviceconfigDelete = true;
            }
            if (Permissionarray[i] == "Practice Configuration : Update practice details") {
              this.serviceConfigPermission.PracticeUpdate = true;
            }
            if (Permissionarray[i] == "Practice Configuration : Update Service details") {
              this.serviceConfigPermission.serviceconfigUpdate = true;
            }
            // ml dashboard
            if (Permissionarray[i] == "Denial Prediction") {
              this.permission.denialepayment = true;
            }
            if (Permissionarray[i] == "  Forecast Allowed Amount") {
              this.permission.forecastalowedpayment = true;
            }
          }

          //service config permission 
          //localStorage.setItem( "serviceConfig", JSON.stringify(this.serviceConfigPermission));
          // Encryption
          const serviceConfigPermission = JSON.stringify(this.serviceConfigPermission);
          localStorage.setItem('serviceConfig', this.tokenEncryptDecrypt.encryptToken(serviceConfigPermission));

          // main menu
          if (this.permission.Covid == true || this.permission.QualityDashboard2021 == true || this.permission.patienttracking == true) {
            this.ClinicalMain = true;
          } else {
            this.ClinicalMain = false;
          }
      
          if (this.ClinicalMain == true) {
            if (this.permission.QualityDashboard2021 == true) {
              this.mipsSeconMenu = true;
            } else {
              this.mipsSeconMenu = false;
            }
          }
          if (this.ClinicalMain == true) {
            if (this.permission.QualityDashboard2021PatientSummary == true) {
              this.mipsSeconMenu = true;
            } else {
              this.mipsSeconMenu = false;
            }
          }

         
          // QBOT
          if (this.permission.qbotdashboard == true || this.permission.dashboardloadingtime == true || this.permission.dashboardupdatedtime == true) {
            this.QBOTMainMenu = true;
          } else {
            this.QBOTMainMenu = false;
          }

          // ---------------------value
          if (this.permission.facilityanalytics || this.permission.hhaanalytics || this.permission.snfanalytics || this.permission.alliancesummaryanalytics
            || this.permission.practicesummaryanalytics) {
            this.valueMainMenu = true;
          } else {
            this.valueMainMenu = false;
          }

          // ---------------------MIU
          if (this.permission.paymentAnalysisMIU || this.permission.chargesAnalysisMIU || this.permission.keyMetricsMIU
            || this.permission.MIUproductivity || this.permission.ExpectedConfigMIU || this.permission.MIUdailyTracker || this.permission.ARMIUanalysis || this.permission.miufinancialprod || this.permission.MiuDenialManagement) {
            this.MIUMainMenu = true;
          } else {
            this.MIUMainMenu = false;
          }


          if (this.permission.UtilizationComparison == true) {
            this.utilcompare = true;
          } else {
            this.utilcompare = false;
          }
          if (this.permission.ReconciliationPerformance == true) {
            this.reconperformance = true;
          } else {
            this.reconperformance = false;
          }
          if (this.permission.ActualReconciliation == true) {
            this.actualrecon = true;
          } else {
            this.actualrecon = false;
          }
          if (this.permission.BCBSCommercialActual == true) {
            this.BCBSactual = true;
          } else {
            this.BCBSactual = false;
          }
          if (this.permission.BCBSUtilizations == true) {
            this.BCBSutil = true;
          } else {
            this.BCBSutil = false;
          }
          if (
            this.permission.Ortholowersummary == true ||
            this.permission.OrtholowerIPRiskStratified == true ||
            this.permission.OrtholowerFractures == true ||
            this.permission.OrtholowerReadmissions == true
          ) {
            this.LowerExtremity = true;
          } else {
            this.LowerExtremity = false;
          }
          if (
            this.permission.Orthouppersummary == true ||
            this.permission.OrthoupperIPRiskStratified == true

          ) {
            this.UpperExtremity = true;
          } else {
            this.UpperExtremity = false;
          }
          //  remove after testing
          /* this.UpperExtremity = true;
          this.LowerExtremity = true; */
          //  emove after testing
          if (this.LowerExtremity == true || this.UpperExtremity == true) {
            this.OrthomainMenu = true;
          } else {
            this.OrthomainMenu = false;
          }
          // if (this.OrthomainMenu == true) {
          //   this.valueMainMenu = true;
          // } else {
          //   this.valueMainMenu = false;
          // }
          // -----------------------Qinsight
          if (
            this.permission.QAdminPracticeConfiguration == true ||
            this.permission.ModelConfiguration == true ||
            this.permission.QAdminBillingConfiguration == true ||
            this.permission.ExpectedValueConfig == true ||
            this.permission.DenialConfig == true ||
            this.permission.AddDenialConfig == true ||
            this.permission.RCMUserConfig == true ||
            this.permission.TFLUserConfig == true ||
            this.permission.automailconfig == true ||
            this.permission.QAdminDayWisePayerPayment == true ||
            this.permission.ProviderConfiguration == true ||
            this.permission.ProviderConfigurationbyPOS == true ||
            this.permission.CustomizedDashboard == true ||

            this.permission.PracticeUpdate == true ||
            this.permission.serviceconfigAdd == true ||
            this.permission.serviceconfigDelete == true ||
            this.permission.serviceconfigUpdate == true ||
            this.permission.serviceconfiggroup == true
          ) {
            this.QAdminMainmenu = true;
          } else {
            this.QAdminMainmenu = false;
          }

          if (
            this.permission.BatchDistribution == true ||
            this.permission.BiotechConfiguration == true ||
            this.permission.IncompleteOrder == true ||
            this.permission.Insurancefollowup == true ||
            this.permission.Trizetto == true ||
            // this.permission.BiotechDayWisePayerPayment == true ||
            this.permission.RejectionRate == true ||
            this.permission.Biotechproductivity == true

          ) {
            this.Biotechmainmenu = true;
          } else {
            this.Biotechmainmenu = false;
          }
          if (
            this.permission.ChargesVsPayment == true ||
            this.permission.RCMWaterfall == true ||
            this.permission.RevenueCycle == true ||
            this.permission.ExpectedCollection == true ||
            this.permission.CPTExpectedCollection == true ||
            this.permission.Acountrecivable == true ||
            this.permission.QPMholddashboard == true ||
            this.permission.DenialAnalysis == true ||
            this.permission.DenialsHistory == true ||
            this.permission.DenialManagement == true ||
            this.permission.PanelPayerDistribution == true ||
            this.permission.paneldistribution == true ||
            this.permission.LocationFinancialPayment == true ||
            this.permission.PracticeCompensation == true ||
            this.permission.RCMDayWisePayerPayment == true ||
            this.permission.ProviderCompensation == true ||
            this.permission.ProviderCompensationbyPOS == true ||
            this.permission.ClaimNotWorkedUpon == true ||
            this.permission.PatientPayment == true ||
            this.permission.DailyChargeDetails == true ||
            this.permission.TimelyFiling == true ||
            this.permission.CPTAnalysis == true ||
            this.permission.chargebatchLag == true ||
            this.permission.KeyPerformance == true ||
            this.permission.ExamFinishLag == true ||
            this.permission.Metric == true ||
            this.permission.AgedPayments == true ||
            this.permission.MissingCharges == true ||
            this.permission.MonthlyCharges == true ||
            this.permission.MissedOpportunities == true ||
            this.permission.MonthlyClose == true ||
            this.permission.MtdYtdReport == true ||
            this.permission.DailyCharges == true ||
            this.permission.DailyClose == true ||
            this.permission.MonthlyPayment == true ||
            this.permission.allrcmProductivity == true ||
            this.permission.dellclaimexport == true ||
            this.permission.rcmanalytics == true ||
            this.permission.availablereserve == true ||
            this.permission.appointcensusreport == true ||
            this.permission.rcmProductivity == true ||
            this.permission.erapaymentposting == true ||
            this.permission.Insurancependingchecks == true

          ) {
            this.RCMMainMenu = true;
          } else {
            this.RCMMainMenu = false;
          }
          if (
            this.permission.ERAClientDistribution == true ||
            this.permission.ERADaywiseDistribution == true ||
            this.permission.ERADivisionDistribution == true ||
            this.permission.checkdistribution == true ||
            this.permission.filedistribution == true ||
            this.permission.honestreport == true ||
            this.permission.newfiledistribution == true ||
            this.permission.mhphubreport == true ||
            this.permission.practicesummary == true ||
            this.permission.DayWisePayerPayment == true
          ) {
            this.BanqMainMenu = true;
          } else {
            this.BanqMainMenu = false;
          }

          if (
            this.permission.keyMetrics == true ||
            this.permission.ChargesAnalysis == true ||
            this.permission.paymentAnalysis == true ||
            this.permission.RefundAnalysis == true ||
            this.permission.TakebackAnalysis == true ||
            this.permission.DailyTracker == true ||
            this.permission.PaymentBreakUP == true ||
            this.permission.Paymentwaterfall == true ||
            this.permission.OverallARAndbreakup == true ||
            this.permission.ChemononChemo == true ||
            this.permission.ProviderYTD == true ||
            this.permission.InsuranceDebitARAging == true ||
            this.permission.RenderingProviderYTD == true ||
            this.permission.ProviderCharges == true ||
            this.permission.ProviderPayments == true ||
            this.permission.InsuranceARComparision == true ||
            this.permission.Financialproductivity == true ||
            this.permission.ProviderProductivity == true
            // ||  this.permission.FinancialAR == true
          ) {
            this.MetricsmainMenu = true;
          } else {
            this.MetricsmainMenu = false;
          }

          //projects menu
          if (
            this.permission.home == true
          ) {
            this.MetricsHomeMenu = true;
          } else {
            this.MetricsHomeMenu = false;
          }

          //projects menu
          if (
            this.permission.ProjectInsight == true ||
            this.permission.MyProjects == true
          ) {
            this.ProjectsMenu = true;
          } else {
            this.ProjectsMenu = false;
          }

          // Ortho Lower Extremity
          if (
            this.permission.orthoLowerSummary == true ||
            this.permission.ERADaywiseDistribution == true ||
            this.permission.ERADivisionDistribution == true ||
            this.permission.checkdistribution == true
          ) {
            this.ValueOrthoLowerSummary = true;
          } else {
            this.ValueOrthoLowerSummary = false;
          }

          if (
            this.permission.CPTAllowedvsASPPricing == true ||
            this.permission.MRIDetails == true
          ) {
            this.AdhocMainMenu = true;
          } else {
            this.AdhocMainMenu = false;
          }

          //........... automation
          if (this.permission.Services == true || this.permission.productusage) {
            this.mainMonitor = true;
          } else {
            this.mainMonitor = false;
          }
        }
        // ML dashboard 
       
         if (this.CurrentEnvironment =="Staging") {
          if (
            this.permission.denialepayment === true ||
            this.permission.forecastalowedpayment === true
          ) {
            this.MLMainmenu = true;
          } else {
            this.MLMainmenu = false;
          }
         } else {
           this.MLMainmenu = false;
         }


      });


  }
  sidebarclick() {
    sessionStorage.setItem("bisprovider", "true");
  }

  sidepanel() {
    this.toggle = !this.toggle;
    this.commonService.sidebarSub.next(this.toggle);
  }

  navtoDashboard() {
    let practiceLengh = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("ListofPractice"))); //Decryption  JSON.parse(sessionStorage.getItem("ListofPractice"));
    if (practiceLengh.length > 1) {
      this.router.navigate(["/qdashboards/home"]);
    } else {
      this.router.navigate(["/financialmetrics/keyMetrics"]);
    }
  }
}
